
import dashboardIcon from "../../assets/images/dashboard_icon_gray.svg";
import itIcon from "../../assets/images/icon_it.svg";
import liveDataIcon from "../../assets/images/userManagement.svg";
import statisticsIcon from "../../assets/images/statistics.svg";
import conversionRateIcon from "../../assets/images/conversion_rate_icon.svg";
import bankConnectionFrequencyIcon from "../../assets/images/bank_connection_frequency_icon.svg";
import comparisonReportIcon from "../../assets/images/comparison_report_icon.svg";
import organizationIcon from "../../assets/images/organization_icon_gray.svg";
import testCenterIcon from "../../assets/images/test_center_icon.svg";
import certificateLogIcon from "../../assets/images/certificateLog.svg";
import userManagementIcon from "../../assets/images/liveData.svg";
import usersIcon from "../../assets/images/users_icon_gray.svg";
import emailIcon from "../../assets/images/email_icon_gray.svg";
import smsIcon from "../../assets/images/iphone_icon_gray.svg";
import reportIcon from "../../assets/images/reports.svg";
import invoiceIcon from "../../assets/images/invoice_icon_gray.svg";
import adminIcon from "../../assets/images/admin_log_icon_gray.svg";
import accessCodesIcon from "../../assets/images/demo.svg";

const rawSideBarData = [
  {
    displayName: "Dashboard",
    path: "/dashboard",
    subroutes: ["/total_clients", "/new_clients_week", "/new_clients_month", "/new_clients_year"],
    icon: dashboardIcon,
  },
  {
    displayName: "IT",
    role: ["admin", "it"],
    icon: itIcon,
    children: [
      {
        displayName: "Certificate Deletion",
        path: "/certificate_deletion"
      },
      {
        displayName: "API Documentation",
        path: "/api_documentation"
      },
      {
        displayName: "Organization User",
        path: "/organization_user"
      },
      {
        displayName: "Upload Objects To AWS",
        path: "/upload_files"
      },
      {
        displayName: "Kreditz Providers",
        path: "/kreditz_providers"
      },
      {
        displayName: "Organization Providers",
        path: "/organization_providers"
      },
      {
        displayName: "Service Providers",
        path: "/service_providers"
      },
      {
        displayName: "Service Settings",
        path: "/service_settings"
      },
      {
        displayName: "Healthcheck",
        path: "/healthcheck"
      },
      {
        displayName: "Banners",
        path: "/banners"
      },
      {
        displayName: "Background Jobs",
        path: "/background_jobs"
      },
      {
        displayName: "Audit Report",
        path: "/audit_report"
      }
    ],
  },
  {
    displayName: "Live Data",
    icon: liveDataIcon,
    children: [
      {
        displayName: "Live Data Overview",
        path: "/live_data_overview"
      },
      {
        displayName: "Markets Overview",
        path: "/live_data_markets"
      },
      {
        displayName: "Organizations",
        path: "/live_data_organizations"
      },
      {
        displayName: "Worldmap",
        path: "/live_data_worldmap"
      },
      {
        displayName: "Customers",
        path: "/live_data_customers"
      },
      {
        displayName: "Banks",
        path: "/live_data_banks"
      },
      {
        displayName: "Markets",
        path: "/markets"
      },
    ],
  },
  {
    displayName: "Statistics",
    icon: statisticsIcon,
    children: [
      {
        displayName: "Requests overview",
        path: "/requests"
      },
      {
        displayName: "Vista - Account users",
        path: "/vista_account_users"
      },
      {
        displayName: "Accepted Requests",
        path: "/accepted_requests"
      },
      {
        displayName: "Customer Activity",
        path: "/customer_activity"
      },
      {
        displayName: "Active customers",
        path: "/active_customers"
      },
      {
        displayName: "Providers and Modes",
        path: "/providers_modes"
      },
    ],
  },
  {
    displayName: "Conversion Rate",
    icon: conversionRateIcon,
    children: [
      {
        displayName: "Overview",
        path: "/conversion_rate_overview"
      },
      {
        displayName: "Banks",
        path: "/conversion_rate_banks"
      },
    ],
  },
  {
    displayName: "Bank Connection",
    icon: bankConnectionFrequencyIcon,
    children: [
      {
        displayName: "Bank Frequency",
        path: "/bank_connection_frequency"
      },
      {
        displayName: "Bank Status",
        path: "/bank_status"
      },
    ],
  },
  {
    displayName: "Comparison Report",
    icon: comparisonReportIcon,
    path: "/comparison_report",
  },
  {
    displayName: "Organization Management",
    roleExclusion: ["sales"],
    icon: organizationIcon,
    children: [
      {
        displayName: "Organizations",
        path: "/organizations",
        subroutes: ["/create_organization", "/edit_organization"],
      },
      {
        displayName: "Organizations - No Activity",
        path: "/org_no_activity"
      },
      {
        displayName: "Organizations - Broker Flow",
        path: "/org_broker_flow"
      },
      {
        displayName: "Organizations - Event Log",
        path: "/org_eventlog"
      },
      {
        displayName: "Organizations - Email/SMS",
        path: "/org_email_sms"
      },
      {
        displayName: "Organizations - Notification Emails",
        path: "/org_notification_emails"
      },
      {
        displayName: "Organizations - Endpoints",
        path: "/org_endpoints"
      },
      {
        displayName: "Texts",
        path: "/texts"
      },
    ],
  },
  {
    displayName: "Access Codes",
    role: ["admin", "it"],
    icon: accessCodesIcon,
    children: [
      {
        displayName: "Vista - API Documentation",
        path: "/api_access_codes"
      },
      {
        displayName: "Demo Aggregation",
        path: "/demo_access_codes"
      }
    ],
  },
  {
    displayName: "Test Center",
    icon: testCenterIcon,
    role: ["admin", "it"],
    path: "/testCenter",
    subroutes: ["/createSubscriber", "/activeSubscribers", "/editSubscriber"],
  },
  {
    displayName: "Certificates",
    roleExclusion: ["sales"],
    icon: certificateLogIcon,
    children: [
      {
        displayName: "Certificate Log",
        path: "/certificateLogs"
      },
      {
        displayName: "Certificate Information",
        path: "/certificate_information"
      },
    ],
  },
  {
    displayName: "Email Logs",
    path: "/emails",
    roleExclusion: ["sales"],
    icon: emailIcon,
  },
  {
    displayName: "SMS Logs",
    path: "/sms",
    roleExclusion: ["sales"],
    icon: smsIcon,
  },
  {
    displayName: "Reports",
    path: "/reports",
    roleExclusion: ["sales"],
    icon: reportIcon,
  },
  {
    displayName: "Invoicing",
    path: "/invoicing",
    roleExclusion: ["sales"],
    icon: invoiceIcon,
  },
  {
    displayName: "Admin Logs",
    role: ["admin", "it"],
    path: "/adminLogs",
    icon: adminIcon,
  },
  {
    displayName: "User Management",
    role: ["admin", "it"],
    path: "/user_management",
    subroutes: ["/new", "/edit"],
    icon: userManagementIcon,
  },
  {
    displayName: "Profile",
    path: "/profile",
    subroutes: ["/update_password"],
    icon: usersIcon,
  }
];

const addRolesToChildren = (accessSettings) => {
  const formattedAccess = accessSettings.map((access) => {
    const icon = access.icon || null;
    const role = access.role || [];
    const roleExclusion = access.roleExclusion || [];
    if (access.children) {
      const subAccess = access.children.map((subAccess) => {
        const inheritedRole = subAccess.role || access.role || [];
        const inheritedRoleExclusion = subAccess.roleExclusion || access.roleExclusion || [];
        return {
          displayName: subAccess.displayName,
          path: subAccess.path,
          role: inheritedRole,
          roleExclusion: inheritedRoleExclusion
        }
      })
      const icon = access.icon || null;
      return {
        displayName: access.displayName,
        role,
        roleExclusion,
        icon,
        children: subAccess
      }
    }
    return {
      displayName: access.displayName,
      path: access.path,
      role,
      roleExclusion,
      icon
    }
  })
  return formattedAccess;
}

export const sideBarData = addRolesToChildren(rawSideBarData);