import { useState, useEffect, useCallback } from "react";
import EndpointsTable from "./misc/EndpointsTable";
import "./OrganizationsEndpoints.scss";
import SelectMenu from "./misc/SelectMenu";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrganizationsEndpoints } from "../../Redux/Actions/OrganizationsEndpoints";
import { getOrganizationList } from "../../Redux/Actions/OrganizationManagement";

function OrganizationsEndpoints() {
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedEndpointId, setSelectedEndpointId] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [endpointsList, setEndpointsList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);

  const fetchOrganizationsEndpoints = useCallback(async () => {
    dispatch(setLoaderSpinner(true));
    try {
      const endpointsResponse = await dispatch(getOrganizationsEndpoints());
      if (endpointsResponse.status) {
        setEndpointsList(endpointsResponse.data.data.analytics_endpoints || []);
      } else if (endpointsResponse.message === "Unauthorized") {
        history.push("/login");
      }
    } catch (error) {
      console.error("Error fetching organizations list:", error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  }, [dispatch, history]);

  const fetchOrganizationList = useCallback(async () => {
    dispatch(setLoaderSpinner(true));
    let allOrganizations = [];
    let page = 1;
    let totalPages = 1;
    try {
      do {
        const response = await dispatch(getOrganizationList(`?page=${page}`));
        if (response.status) {
          allOrganizations = allOrganizations.concat(
            response.data.organizations || []
          );
          totalPages = response.data.total_pages;
          page += 1;
        } else if (response.message === "Unauthorized") {
          history.push("/login");
          break;
        }
      } while (page <= totalPages);
      setOrganizationsList(allOrganizations);
    } catch (error) {
      console.error("Failed to fetch all organizations", error);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  }, [dispatch, history]);

  useEffect(() => {
    fetchOrganizationsEndpoints();
    fetchOrganizationList();
  }, [fetchOrganizationList, fetchOrganizationsEndpoints]);

  return (
    <div className="background-jobs-container">
      <div className="lower-header-backgroundJobs">
        <h3 className="endpoint-header">Organizations - Endpoints</h3>
      </div>
      <div className="backgroundJobs-table-top bg-white">
        <div className="backgroundJobs-thead-scroll-y">
          <SelectMenu
            selectedOrgId={selectedOrgId}
            setSelectedOrgId={setSelectedOrgId}
            selectedEndpointId={selectedEndpointId}
            setSelectedEndpointId={setSelectedEndpointId}
            endpointsList={endpointsList}
            organizationsList={organizationsList}
            fetchOrganizationsEndpoints={fetchOrganizationsEndpoints}
            fetchOrganizationList={fetchOrganizationList}
          />
          <EndpointsTable
            selectedOrgId={selectedOrgId}
            selectedEndpointId={selectedEndpointId}
            endpointsList={endpointsList}
            organizationsList={organizationsList}
            fetchOrganizationsEndpoints={fetchOrganizationsEndpoints}
            fetchOrganizationList={fetchOrganizationList}
            linkPath="/org_endpoints/"
          />
        </div>
      </div>
    </div>
  );
}

export default OrganizationsEndpoints;