import { useState } from "react";
import ReactSelect from "react-select";
import { updateOrganizationEndpoint } from "../../../Redux/Actions/OrganizationsEndpoints";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";

const UpdateEndpointModal = ({
  handleCloseModal,
  endpoint,
  organizationsList,
  organizationNames,
  setFlash,
}) => {
  const [selectedOrg, setSelectedOrg] = useState(
    organizationsList
      .filter((org) => organizationNames.includes(org.name)) 
      .map((org) => ({ value: org.id, label: org.name })) 
  );
  const [selectedStatus, setSelectedStatus] = useState({
    value: endpoint.status,
    label: endpoint.status.charAt(0).toUpperCase() + endpoint.status.slice(1).toLowerCase(),
  });
  const dispatch = useDispatch();

  const endpointStatus = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleOrgChange = (selectedOptions) => {
    setSelectedOrg(selectedOptions);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption || "");
  };

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("endpoint-modal")) {
      handleCloseModal();
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const selectedOrgIds = selectedOrg.map((org) => org.value);
    const updatePayload = {
      analytics_endpoint: {
        id: endpoint.id,
        status: selectedStatus.value,
      },
      organization_ids: selectedOrgIds,
    };
    dispatch(setLoaderSpinner(true));
    try {
      const response = await dispatch(updateOrganizationEndpoint(updatePayload));
      if (response.status) {
        setFlash({ show: true, type: "success", message: "Endpoint successfully updated!" });
        handleCloseModal();
      } else {
        setFlash({ show: true, type: "error", message: response.message });
      }
    } catch (error) {
      console.error("Error:", error);
      setFlash({ show: true, type: "error", message: "An error occurred while updating the endpoint." });
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  };

  return (
    <div className="endpoint-modal" onClick={handleBackdropClick}>
      <div className="endpoint-modal-content">
        <h3>Edit Endpoint</h3>
        <form onSubmit={handleEditSubmit} className="endpoint-form">
          <div className="endpoint-form-group-row">
            <div className="endpoint-form-group">
              <label className="endpoint-label">Status:</label>
              <ReactSelect
                value={selectedStatus}
                onChange={handleStatusChange}
                options={endpointStatus}
                placeholder="Select status"
                isClearable={true}
                className="endpoint-select-org-dropdown"
              />
            </div>
            <div className="endpoint-form-group">
              <label className="endpoint-label">Organization:</label>
              <ReactSelect
                value={selectedOrg}
                onChange={handleOrgChange}
                options={organizationsList.map((org) => ({
                  value: org.id,
                  label: org.name,
                }))}
                placeholder={"Select organization"}
                isClearable={true}
                isMulti={true}
                noOptionsMessage={() => "No organizations found"}
                className="endpoint-select-org-dropdown"
              />
            </div>
          </div>
          <div className="endpoint-modal-buttons-container">
            <button type="submit" className="endpoint-button">
              Update
            </button>
            <button
              type="button"
              className="endpoint-button"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateEndpointModal;