import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { SET_BANK_LIST, SET_BANK } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function createOrganization(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.CREATE()}`;
  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getInvitationLink(id) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.GET_INVITATION()}` + id;
  return dispatch => axios.post(apiURL)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function sendInvitationViaEmail(data) {
  const apiURL = `${API_CONFIG.BASE_AUTH_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.MIDDLE_PATH}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.SEND_INVITATION_VIA_EMAIL()}` + data;
  return dispatch => axios.post(apiURL)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function updateOrganization(data, id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.UPDATE() + id}`;

  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationList(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_LIST() + data}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationListNoActivity(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_LIST_NO_ACTIVITY()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}


export function removeOrganization(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.DELETE() + id}`;

  return dispatch => axios.delete(apiURL)
    .then(res => {
      return { status: true, message: res.data.status };
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear()
      }
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function removeServiceProvider(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_PROVIDER_DELETE() + id}`;

  return dispatch => axios.delete(apiURL)
    .then(res => {
      return { status: true, message: res.data.status };
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear()
      }
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationById(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_BY_ID() + `id=${id}`}`;

  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getOrganizationBanks(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.BANK_LIST() + data}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      dispatch(setBankList(res.data.data))
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getServiceProviders(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.SERVICE_PROVIDERS_LIST()}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}


export function getBankDetails(data, id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.BANK_DETAILS() + data}`;

  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: res.data.status === 'error' ? false : true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function createBankCustomMessage(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.CREATE_CUSTOM_BANK_MESSAGE()}`;

  return dispatch => axios.post(apiURL, data)
    .then(res => {
      return { status: res.data.status === 'error' ? false : true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getBankCustomMessage(data) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.FIND_CUSTOM_BANK_MESSAGE() + data}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: res.data.status === 'error' ? false : true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function updateBankCustomMessage(data, id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.UPDATE_CUSTOM_BANK_MESSAGE() + id + '/update_custom_bank_message'}`;

  return dispatch => axios.put(apiURL, data)
    .then(res => {
      return { status: res.data.status === 'error' ? false : true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function setBankList(responseData) {
  return {
    type: SET_BANK_LIST,
    bank_list: responseData
  };
};

export function getOrganizationAccountList(param = '?') {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_ACCOUNT_LIST()}` + param;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function setBank(responseData) {
  return {
    type: SET_BANK,
    bank: responseData
  };
};

export function getOrganizationBrokerDetails(params) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.ORGANIZATION_BROKER_DETAILS()}`;
  return dispatch => axios.get(apiURL, { params })
    .then(res => {
      return { status: true, message: res.data.status, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}

export function getBrokerFlowXlsx(startDate, endDate) {
  let params = '';
  if (startDate && endDate) {
    params = `?startDate=${startDate}&endDate=${endDate}`;
  }
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.BROKER_FLOW_EXPORT()}${params}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, data: res.data };
    }).catch(err => {
      if (err.response && err.response.status === 401) removeHeaderWithLocalstorage();
      return { status: false, message: err.response ? err.response.data.message : 'Network error' };
    });
}


export function getDownloadSettings(id) {
  const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.ORGANIZATION_MANAGEMENT.DOWNLOAD_SETTINGS() + id}`;
  return dispatch => axios.get(apiURL)
    .then(res => {
      return { status: true, message: res.data.message, data: res.data.data };
    }).catch(err => {
      (err.response.status === 401) && removeHeaderWithLocalstorage();
      return { status: false, message: err.response.data.error || err.response.data.message }
    })
}