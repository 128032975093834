import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import _ from "lodash"
import { EditorState, convertFromHTML, draftToHtml, ContentState, compositeDecorator, convertToRaw } from 'draft-js';
import { getBankCustomMessage, getBankDetails } from '../../../Redux/Actions/OrganizationManagement';
import { getBankPriorityUpdate } from '../../../Redux/Actions/OrganizationProvider';

function BankListTab({
  clientDetails,
  bankFilters,
  setBankFilters,
  languages,
  bankFilterCountries,
  bankFilterCountriesCorporate,
  country,
  banks,
  setBankSearch,
  bankSearch,
  setBanks,
  fetchBankList,
  setFoundElement,
  showMessage,
  foundElement,
  setCustomBankDetails,
  customBankDetails,
  organization_id,
  setShowMessage,
  _updateBankCustomMessage,
  searchIcon,
  openFlash
}) {
  const [currentBankId, setCurrentBankId] = useState(null)
  const dispatch = useDispatch()
  const BankList = useSelector(state => state.organization_management?.bank_list);
  const initialElement = {}
  var editorState = EditorState.createEmpty()
  const [description, setDescription] = useState(editorState);
  const dragItem = useRef();
  const dragOverItem = useRef();


  const handleChangeType = (e) => {
    const { name, value } = e.target
    setBankFilters(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e) => {
    const copyListItems = [...banks];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setBanks(copyListItems);
    let bank = copyListItems.map((item, index) => ({ organization_provider_id: item.organization_provider_id, index: index }))
    let bankData = { bank }
    const priorityResponse = await dispatch(getBankPriorityUpdate(bankData))
    const { status, message } = priorityResponse;
    if (status) {
      openFlash("tick", "Done!", message)
    }
  };

  const handleBankSearch = (e) => {
    setBankSearch(e.target.value)
    if (e.target.value !== '') {
      let _banks = _.filter(BankList, function (f) {
        if (f.display_name.toLowerCase().match(e.target.value.toLowerCase())) {
          return f
        }
      })
      setBanks(_banks)
    } else {
      fetchBankList()
    }
  }

  const expandMessage = async (id) => {
    setFoundElement(initialElement)
    setCurrentBankId(id)
    setDescription(editorState)
    let bQuery =
      '?type=' + bankFilters.type +
      '&country_id=' + bankFilters.country_id +
      "&locale=" + bankFilters.locale +
      "&kreditz_provider_id=" + id +
      "&organization_id=" + organization_id

    const responseBankMessage = await dispatch(getBankCustomMessage(bQuery))
    if (responseBankMessage && responseBankMessage.status && responseBankMessage.data && responseBankMessage.data.custom_message) {
      let found = banks.find(element => { return element.id === id })
      found.message = responseBankMessage.data && responseBankMessage.data.custom_message && responseBankMessage.data.custom_message !== null && responseBankMessage.data.custom_message !== undefined ? responseBankMessage.data.custom_message : ''

      // CONVERT CUSTOM MEESAGE WITH EDITABLE FORMAT
      const blocksFromHTML = convertFromHTML(found.message)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      editorState = EditorState.createWithContent(state, compositeDecorator)
      setDescription(editorState)


      setFoundElement(found)
      setShowMessage(true)

      if (customBankDetails && customBankDetails.length > 0) {
        if (customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id) > -1) {
          let index = customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id)
          customBankDetails[index].custom_message = responseBankMessage.data && responseBankMessage.data.custom_message
          customBankDetails[index].custom_id = responseBankMessage.data && responseBankMessage.data.id
          setCustomBankDetails([...customBankDetails])
        } else {
          let newArr = customBankDetails.filter(item => item.kreditz_provider_id !== id)
          let obj = {
            kreditz_provider_id: id,
            custom_message: responseBankMessage.data && responseBankMessage.data.custom_message && responseBankMessage.data.custom_message !== null && responseBankMessage.data.custom_message !== undefined ? responseBankMessage.data.custom_message : '',
            message_type: 'CustomMessage',
            custom_id: responseBankMessage.data && responseBankMessage.data.id
          }
          newArr.push(obj)
          setCustomBankDetails([...newArr])
        }
      } else {
        let newArr = []
        let obj = {
          kreditz_provider_id: id,
          custom_message: responseBankMessage.data && responseBankMessage.data.custom_message !== undefined ? responseBankMessage.data.custom_message : '',
          message_type: 'CustomMessage',
          custom_id: responseBankMessage.data && responseBankMessage.data.id
        }

        newArr.push(obj)
        setCustomBankDetails([...newArr])
      }
    } else {
      let query =
        '?type=' + bankFilters.type +
        '&country_id=' + bankFilters.country_id +
        "&locale=" + bankFilters.locale +
        "&kreditz_provider_id=" + id

      const response = await dispatch(getBankDetails(query))
      if (response.status) {
        let found = banks.find(element => { return element.id === id })
        found.message = response.data && response.data.default_message !== undefined ? response.data.default_message : ''

        // CONVERT CUSTOM MEESAGE WITH EDITABLE FORMAT
        const blocksFromHTML = convertFromHTML(found.message)
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );

        editorState = EditorState.createWithContent(state, compositeDecorator)
        setDescription(editorState)

        setFoundElement(found)
        setShowMessage(true)
        if (customBankDetails && customBankDetails.length > 0) {
          if (customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id) > -1) {
            let index = customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id)
            customBankDetails[index].custom_message = response.data && response.data.default_message
            customBankDetails[index].custom_id = null
            setCustomBankDetails([...customBankDetails])
          } else {
            let newArr = customBankDetails.filter(item => item.kreditz_provider_id !== id)
            let obj = {
              kreditz_provider_id: id,
              custom_message: response.data && response.data.default_message !== undefined ? response.data.default_message : '',
              message_type: 'CustomMessage',
              custom_id: null
            }
            newArr.push(obj)
            setCustomBankDetails([...newArr])
          }
        } else {
          let newArr = []
          let obj = {
            kreditz_provider_id: id,
            custom_message: response.data && response.data.default_message !== undefined ? response.data.default_message : '',
            message_type: 'CustomMessage',
            custom_id: null
          }

          newArr.push(obj)
          setCustomBankDetails([...newArr])
        }
      } else {
        let found = banks.find(element => { return element.id === id })
        found.message = response.data && response.data.default_message !== undefined ? response.data.default_message : ''
        // CONVERT CUSTOM MEESAGE WITH EDITABLE FORMAT
        const blocksFromHTML = convertFromHTML(found.message)
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );

        editorState = EditorState.createWithContent(state, compositeDecorator)
        setDescription(editorState)

        setFoundElement(found)
        setShowMessage(true)
        if (customBankDetails && customBankDetails.length > 0) {
          if (customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id) > -1) {
            let index = customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id)
            customBankDetails[index].custom_message = response.data && response.data.default_message
            customBankDetails[index].custom_id = null
            setCustomBankDetails([...customBankDetails])
          } else {
            let newArr = customBankDetails.filter(item => item.kreditz_provider_id !== id)
            let obj = {
              kreditz_provider_id: id,
              custom_message: '',
              message_type: 'CustomMessage',
              custom_id: null
            }
            newArr.push(obj)
            setCustomBankDetails([...newArr])
          }
        } else {
          let newArr = []
          let obj = {
            kreditz_provider_id: id,
            custom_message: '',
            message_type: 'CustomMessage',
            custom_id: null
          }

          newArr.push(obj)
          setCustomBankDetails([...newArr])
        }
      }
    }
  }

  const setEditorState = (editorState) => {
    let id = currentBankId
    const convertedData = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(convertedData);
    setDescription(editorState)
    setFoundElement(prevState => ({
      ...prevState,
      message: convertedData && convertedData.blocks && convertedData.blocks[0].text === '' ? '' : markup
    }))
    if (customBankDetails && customBankDetails.length > 0) {
      if (customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id) > -1) {
        let index = customBankDetails.map(function (e) { return e.kreditz_provider_id; }).indexOf(id)
        customBankDetails[index].custom_message = convertedData && convertedData.blocks && convertedData.blocks[0].text === '' ? '' : markup
        setCustomBankDetails([...customBankDetails])
      } else {
        let newArr = []
        let obj = {
          kreditz_provider_id: id,
          custom_message: convertedData && convertedData.blocks && convertedData.blocks[0].text === '' ? '' : markup,
          message_type: 'CustomMessage'
        }
        newArr.push(obj)
        setCustomBankDetails([...newArr])
      }
    }
  }

  return (
    <div id="banklist" className="hide">
      <div className="detail-two-box row">
        <div className="col details-left details-full-box">
          <h3 className="headeing-switch">Notification message for banks</h3>
          <div className="form-group">
            <label>Select type of bank flow</label>
            <select name="type" value={bankFilters.type} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {
                clientDetails.market_ids && clientDetails.market_ids.length > 0 && clientDetails.customer_enabled && (
                  <option value="private" >Consumer</option>
                )
              }
              {
                clientDetails.corporate_market_ids && clientDetails.corporate_market_ids.length > 0 && clientDetails.corporate_enabled && (
                  <option value="business" >Business</option>
                )
              }
            </select>
          </div>
          <br></br>
          <div className="form-group">
            <label>Select language</label>
            <select name="locale" value={bankFilters.locale} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {languages.map((lang, index) =>
                <option
                  className='admin-option'
                  key={`admin - option - ${index + 1}`}
                  value={lang.code}>
                  {lang.name}
                </option>
              )};
            </select>
          </div>
          <br></br>
          <div className="form-group">
            <label>Select market</label>
            <select name="country_id" value={bankFilters.country_id} className="form-control bankflow-form org-excludeDropdown" onChange={(e) => handleChangeType(e)}>
              {
                bankFilters?.type === "private" ? (
                  clientDetails?.market_ids && clientDetails?.market_ids?.length > 0 ? (
                    bankFilterCountries.map((_country, index) =>
                      <option
                        className='admin-option'
                        key={`admin - option - ${index + 1}`}
                        value={_country.id}>
                        {_country.name}
                      </option>
                    )) :
                    (
                      country.map((_country, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={_country.id}>
                          {_country.name}
                        </option>
                      )
                    )) : (clientDetails?.corporate_market_ids && clientDetails?.corporate_market_ids?.length > 0 ? (
                      bankFilterCountriesCorporate.map((_country, index) =>
                        <option
                          className='admin-option'
                          key={`admin - option - ${index + 1}`}
                          value={_country.id}>
                          {_country.name}
                        </option>
                      )) :
                      (
                        country.map((_country, index) =>
                          <option
                            className='admin-option'
                            key={`admin - option - ${index + 1}`}
                            value={_country.id}>
                            {_country.name}
                          </option>
                        )
                      ))
              }
            </select>
          </div>
        </div>
        <div className="col details-middle details-full-box">
          <h3 className="headeing-switch">Select bank</h3>
          <div className="select-search tab-date">
            <div className="search-bankflow-input">
              <input
                className="search-bankflow-input"
                name="search"
                placeholder="Search your bank"
                value={bankSearch}
                onChange={(e) => handleBankSearch(e)}
              />
              <img className="search-bankflow-icon" src={searchIcon} alt="searchIcon" />
            </div>
          </div>
          <div className="bank-overflow-scroll">
            {banks && banks.map((_bank, index) => (
              <div className="banks-boxes"
                onClick={(e) => expandMessage(_bank?.kreditz_provider_id)}
                draggable
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
              >
                <figure><img className="banks-icon" src={_bank.logo && _bank.logo.url} alt="bankkicon" /></figure>
                <div className="bank-action-icons">
                  <p title={_bank.display_name}>{_bank.display_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col details-right details-full-box">
          <h3 className="headeing-switch">Message</h3>
          {showMessage ?
            <div className="banks-boxes">
              <span className="bank-message-header"><img className="banks-icon" src={foundElement.logo && foundElement.logo.url} alt="bankHeader" />{foundElement.display_name}</span>
              <br></br>
              <p className="message-entering">Enter message</p>
              <div className="input-info-bank">
                <Editor
                  editorState={description}
                  onEditorStateChange={setEditorState}
                  // onEditorStateChange={(e)=> setEditorState(e, foundElement.id)}
                  // value={foundElement.message}
                  // onChange={(e)=> handleChangeText(e, foundElement.id)}
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />
              </div>
              {/*<textarea className="input-info-bank" name="message" onChange={(e)=> handleChangeText(e,foundElement.id)} value={foundElement.message}/>*/}
              <button className="update-bank-message" onClick={() => _updateBankCustomMessage(foundElement.kreditz_provider_id)}>Update</button>
            </div> :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default BankListTab;
