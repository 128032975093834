import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editUser } from "../../Redux/Actions/UserManagement";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import FlashMessage from "../FlashMessage/FlashMessage";
import { AccessSettings } from "./AccessSettings";

// ASSETS
import infoIcon from "../../assets/images/info.svg";
import backIcon from "../../assets/images/icon_back.png";

// STYLING
import "./EditSomeUser.scss";
import { getAllCountry } from "../../Redux/Actions/Countries";

const EditSomeUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const getUser = history.location.state?.user[0];

  const [name, setName] = useState(getUser?.name ? getUser?.name : '');
  const [email, setEmail] = useState(getUser?.email ? getUser?.email : '');
  const [phone_country_code, setPhone_Country_Code] = useState(getUser?.phone_country_code ? getUser?.phone_country_code : '+46');
  const [phone_number, setPhone] = useState(getUser?.phone_number ? getUser?.phone_number : '');
  const [role, setRole] = useState(getUser?.role ? getUser?.role : '');
  const [userAccessSettings, setUserAccessSettings] = useState(getUser?.access_settings ? getUser?.access_settings : {});
  const [userSettingsRole, setUserSettingsRole] = useState();

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [countries, setCountries] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const parameters = {
      users: {
        name,
        email,
        phone_country_code,
        phone_number,
        role,
        access_settings: userAccessSettings
      },
    };
    const { status, message } = await dispatch(
      editUser(parameters, getUser.id)
    );
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('phone_country_code', phone_country_code);
    localStorage.setItem('phone_number', phone_number);
    if (status) {
      openFlash("tick", "Done!", message);
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      } else {
        openFlash("alert", "Error!", message);
      }
    }
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }
  const updateRole = (e) => {
    setRole(e.target.value);
    setUserSettingsRole(e.target.value);
  }
  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const { data } = await dispatch(getAllCountry());
    const { countries_list } = data;
    setCountries(countries_list);
  };

  const updateUserAccessSettings = (accessSettings) => {
    setUserAccessSettings(accessSettings);
  }

  return (
    <div className="new-user-page-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <h3 className="basic-head01 basic-flex-start">
        <div className="back-heading">
          <span
            onClick={() => history.push("/user_management")}
            className="esu-icon-wrapper"
          >
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
          Edit user
        </div>
      </h3>
      <div className="bg-white-editUser">
      <div className="edit-user-container">
        <form className="password-form" onSubmit={handleSubmit}>
          <div>
              <div>
                <div className="row">
                  <div className="form-group" style={{ maxWidth: "300px" }}>
                    <label className="labelFormUser">Name</label>
                    <input
                      type="text"
                      style={{ fontSize: "14px" }}
                      className="form-control"
                      placeholder="Enter name"
                      name="name"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{ maxWidth: "300px" }}>
                    <label className="labelFormUser">Email</label>
                    <input
                      style={{ fontSize: "14px" }}
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group cnu-input-wrapper"
                    style={{ maxWidth: "300px" }}
                  >
                    <label className="labelCreateFormUser">Phone</label>
                    <div className="flex-select">
                      <select className="form-control org-excludeDropdown"
                        id="sel1"
                        value={phone_country_code}
                        name="phone_country_code"
                        onChange={(e) => setPhone_Country_Code(e.target.value)} >
                        {countries.map((_country, index) =>
                          <option
                            rules={{ required: true }}
                            className='admin-option'
                            key={`admin-option - ${index + 1}`}
                            value={_country.phone_country_code}>
                            {_country.phone_country_code}
                          </option>
                        )};
                      </select>
                      <input
                        type="Phone"
                        style={{ fontSize: "14px" }}
                        className="form-control"
                        placeholder="Enter phone"
                        value={phone_number}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{ maxWidth: "300px" }}>
                    <label className="labelFormUser">Role</label>
                    <select
                      className="excludeDropdown"
                      style={{ fontSize: "14px" }}
                      placeholder="role"
                      value={role}
                      name="role"
                      onChange={updateRole}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="admin">Admin</option>
                      <option value="sales">Sales</option>
                      <option value="operations">Operations</option>
                      <option value="it">IT</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="create-button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </form>
            <AccessSettings userAccessSettings={userAccessSettings} roleUpdater={userSettingsRole} role={role} updateUserAccessSettings={updateUserAccessSettings} />
          </div>
        <div className="row">
          <div className="col-md-12 mb-5">
            <div
              className="note-modal"
              style={{ margin: "50px 0px 70px 0px !important" }}
            >
              <img src={infoIcon} alt="infoIcon" />
              <div className="note-info">
                <p>
                  <span>Admin</span> **
                </p>
                <p>
                  <span>Sales person</span> **
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSomeUser;
