import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import eyeIcon from '../../../assets/images/eye_icon.svg';

const EndpointsTable = ({
  selectedOrgId,
  selectedEndpointId,
  endpointsList,
  organizationsList,
  linkPath,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [animatedOrgs, setAnimatedOrgs] = useState({});

  const formatDateTime = (dateString) =>
    new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

  const statusToClass = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "success-result";
      case "inactive":
        return "failed-result";
      default:
        return "";
    }
  };

  const filteredEndpoints = (endpointsList || []).filter((endpoint) => {
    const matchesOrg =
      !selectedOrgId ||
      organizationsList.some(
        (org) =>
          org.id === selectedOrgId && org.analytics_endpoint_id === endpoint.id
      );
    const matchesEndpoint =
      !selectedEndpointId || endpoint.id === selectedEndpointId;
    return matchesOrg && matchesEndpoint;
  });

  const toggleRowExpansion = (endpointId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(endpointId)
        ? prevExpandedRows.filter((id) => id !== endpointId)
        : [...prevExpandedRows, endpointId]
    );
  };

useEffect(() => {
  if (expandedRows.length > 0) {
    const newAnimatedOrgs = {};
    expandedRows.forEach(rowId => {
      newAnimatedOrgs[rowId] = true;
    });
    setAnimatedOrgs(newAnimatedOrgs);
  } else {
    setAnimatedOrgs({});
  }
}, [expandedRows]);

return (
  <div className="backgroundJobs-thead-scroll-y">
    {filteredEndpoints.length === 0 ? (
      <div className="endpoint-no-message">
        No endpoints available for the selected organization or endpoint
      </div>
    ) : (
      <table className="backgroundJobs-table" width="100%">
        <thead className="endpoint-details-thead">
          <tr>
            <th className="backgroundJobs-head">Name</th>
            <th className="backgroundJobs-head">Endpoint</th>
            <th className="backgroundJobs-head">Organization</th>
            <th className="backgroundJobs-head">Created</th>
            <th className="backgroundJobs-head">Status</th>
            <th className="backgroundJobs-head"></th>
          </tr>
        </thead>
        <tbody className="endpoint-tbody">
          {filteredEndpoints.map((endpoint) => {
            const endpointStatusClass = statusToClass(endpoint.status);
            const organizationNames = organizationsList
              .filter((org) => org.analytics_endpoint_id === endpoint.id)
              .map((org) => org.name);
            const isExpanded = expandedRows.includes(endpoint.id);

            return (
              <React.Fragment key={endpoint.id}>
                <tr className="user-box">
                  <td>{endpoint.name}</td>
                  <td>{endpoint.endpoint}</td>
                  <td onClick={() => toggleRowExpansion(endpoint.id)}>
                    {organizationNames.length > 0
                      ? organizationNames.length
                      : "-"}
                    {organizationNames.length > 0 && (
                      <button className="endpoint-expand-button">
                        {isExpanded ? (
                          <FontAwesomeIcon icon={faSortUp} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} size="1x" />
                        )}
                      </button>
                    )}
                  </td>
                  <td>{formatDateTime(endpoint.created_at)}</td>
                  <td className={endpointStatusClass}>
                    <div className="capitalize-first-letter">
                      {endpoint.status}
                    </div>
                  </td>
                  <td>
                  {endpoint.id && (
                    <Link to={{
                      pathname: `${linkPath}${endpoint.id}`,
                      state: { endpoint, organizationNames }
                    }}>
                      <img src={eyeIcon} alt="View Details" />
                    </Link>
                  )}
                </td>
                </tr>
                {isExpanded && organizationNames.length > 0 && (
                  <tr className="endpoint-expanded-row">
                    <td colSpan="7" className="endpoint-expanded-content">
                      <div className="endpoint-organizations-list">
                        {organizationsList
                          .filter(
                            (org) => org.analytics_endpoint_id === endpoint.id
                          )
                          .map((org, index) => (
                            <div 
                              key={index} 
                              className={`endpoint-organization-item ${animatedOrgs[endpoint.id] ? 'animate-in' : ''}`}
                              style={{ animationDelay: `${index * 50}ms` }}
                            >
                              {org.name}
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    )}
  </div>
);
};

export default EndpointsTable;