import React from 'react';
import { useEffect } from 'react';
import OrganizationsEndpoints from '../../Components/OrganizationsEndpoints/OrganizationsEndpoints';
import EndpointPage from '../../Components/OrganizationsEndpoints/misc/EndpointPage';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import { Switch, Route } from 'react-router-dom';

const OrganizationsEndpointsPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Organizations Endpoints";
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <Switch>
          <Route exact path="/org_endpoints" component={OrganizationsEndpoints} />
          <Route path="/org_endpoints/:id" component={EndpointPage} />
        </Switch>
      </div>
    </div>
  );
};

export default OrganizationsEndpointsPage;
