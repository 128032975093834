import { useState } from "react";
import ReactSelect from "react-select";

const CreateEndpointModal = ({
  endpointName,
  endpointValue,
  setEndpointName,
  setEndpointValue,
  organization,
  setOrganization,
  organizationsList,
  handleSubmit,
  handleCloseModal,
}) => {
  const [selectedOrgs, setSelectedOrgs] = useState(
    organization ? organizationsList.filter((org) => organization.includes(org.id)) : []);

  const handleOrgChange = (selectedOptions) => {
    if (selectedOptions) {
      const selectedOrgIds = selectedOptions.map((org) => org.value);
      setOrganization(selectedOrgIds);
    } else {
      setOrganization([]);
    }
    setSelectedOrgs(selectedOptions);
  };

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("endpoint-modal")) {
      handleCloseModal();
    }
  };

  const handleInputChange = (field) => (e) => {
    if (field === "name") {
      setEndpointName(e.target.value);
    } else if (field === "endpoint") {
      setEndpointValue(e.target.value);
    }
  };

  return (
    <div className="endpoint-modal" onClick={handleBackdropClick}>
      <div className="endpoint-modal-content">
        <h2>Create Endpoint</h2>
        <form onSubmit={handleSubmit} className="endpoint-form">
          <div className="endpoint-form-group">
            <label className="endpoint-label">Name:</label>
            <input
              type="text"
              value={endpointName || ""}
              onChange={handleInputChange("name")}
              required
              className="endpoint-input"
            />
          </div>
          <div className="endpoint-form-group">
            <label className="endpoint-label">Endpoint:</label>
            <input
              type="text"
              value={endpointValue || ""}
              onChange={handleInputChange("endpoint")}
              required
              className="endpoint-input"
            />
          </div>
          <div className="endpoint-form-group">
            <label className="endpoint-label">Organizations:</label>
            <ReactSelect
              value={selectedOrgs}
              onChange={handleOrgChange}
              options={organizationsList.map((org) => ({
                value: org.id,
                label: org.name,
              }))}
              placeholder="Select organization(s)"
              isClearable={true}
              isMulti={true}
              noOptionsMessage={() => "No organizations found"}
              className="endpoint-select-org-dropdown"
            />
          </div>
          <div className="endpoint-modal-buttons-container">
            <button type="submit" className="endpoint-button">
              Create
            </button>
            <button
              type="button"
              className="endpoint-button"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEndpointModal;