import React, { useEffect, useRef, useState } from 'react';
import ToggleField from '../ToggleField/ToggleField';
import Select from 'react-select'
import "../V4CustomizationTab/downloadReportV4.css"
import { useDispatch } from 'react-redux';
import { getDownloadSettings } from '../../../Redux/Actions/OrganizationManagement';
import ReactToPrint from 'react-to-print';


const RenderPDF = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <pre>{JSON.stringify(props.jsonData, null, 2)}</pre>
    </div>
  )
})

function V4CustomizationTab({ setClientDetails, clientDetails, capitalizeFirstLetter, handleSetting, submitHandle, organizationList, sharedOrganizationSelect, orgId, openFlashError }) {
  const dispatch = useDispatch()
  const componentRef = useRef();
  const [pdfData, setPdfData] = useState()
  const [orgIdShow, setOrgIdShow] = useState('')

  let downloads = clientDetails?.setting?.automate_download_report || []

  const handleCustomizations = (event, name) => {
    let setting = clientDetails.setting
    setting.delivery_via = event.target.value
    setClientDetails(prevState => ({
      ...prevState,
      setting: setting
    }))
  }

  const multiSelectHandler = (selected) => {
    let newSharedIds = selected.map(item => item.value)
    const removedIds = sharedOrganizationSelect.filter(item1 => !selected.some(item2 => item2.value === item1.value && item2.label === item1.label)).map(item => item.value);
    setClientDetails(prevState => ({
      ...prevState,
      shared_organization_ids: newSharedIds,
      remove_ids: removedIds
    }))
  }

  const handleWebhookEvents = (event, name, enable_back_button) => {
    let setting = clientDetails.setting;
    if (enable_back_button === 'enable_back_button') {
      setting.enable_back_button[name] = event.target.checked;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    } else {
      setting.webhook_events[name] = event.target.checked
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
    if (name === "error_response") {
      setting.error_response = event.target.checked;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
    if (name === "use_old_http_status_on_v4") {
      setting.use_old_http_status_on_v4 = event.target.checked;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(0, 82, 204)' : 'white',
      color: state.isSelected ? 'rgb(0, 82, 204)' : 'black'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'rgb(0, 82, 204)',
      backgroundColor: "#bfdbfe"
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'rgb(0, 82, 204)',
      backgroundColor: "#bfdbfe",
      ':hover': {
        backgroundColor: "#bfdbfe",
        color: 'rgb(0, 82, 204)'
      }
    })
  };

  const updateClientDetails = (list) => {
    let setting = clientDetails.setting;
    setting.automate_download_report = list
    setClientDetails(prevState => ({
      ...prevState,
      setting: setting
    }))
  }

  const downloadReportHandler = (event, key) => {
    let updatedDownloads;
    const { checked } = event.target;
    const reportType = key.split('_')[0]; // Extract report type from key

    if (checked) {
      updatedDownloads = [
        ...downloads,
        { report_type: reportType, file_type: key.split('_')[1] }
      ];
    } else {
      updatedDownloads = downloads.filter(data => data.file_type !== key.split('_')[1]);
    }
    updateClientDetails(updatedDownloads)
  }

  const isValueAvailable = (value) => {
    return downloads.some(item => item.file_type === value)
  }

  const localeValue = (value) => {
    let res = downloads?.find(item => item?.file_type === value ? item : '')
    return res?.locale
  }

  const localeUpdateHandler = (event, name) => {
    let result
    result = downloads.map(item => item.file_type === name ? ({ ...item, locale: event.target.value }) : item)
    updateClientDetails(result)
  }

  useEffect(() => {
    downloadSettingHandler()
  }, [])

  const downloadSettingHandler = async () => {
    const response = await dispatch(getDownloadSettings(orgId))
    if (response && response.status) {
      const { organization, setting } = response?.data;
      setOrgIdShow(organization?.id)
      const mergedData = {
        organization,
        setting
      }
      setPdfData(mergedData)
    } else {
      openFlashError("", "Error", response.message)
    }
  }


  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToJson = (e, id) => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(pdfData),
      fileName: `organization-${id}.json`,
      fileType: 'text/json',
    })
  }

  return (
    <div id="options_details" className="hide">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <h3 className="headeing-switch-light">
            Delivery Via
            <div className="form-group" style={{ width: "216px" }}>
              <select className="form-control org-excludeDropdown" id="sel1" name='delivery_via' value={clientDetails.setting.delivery_via} onChange={handleCustomizations}>
                <option value='callback'>callback</option>
                <option value='webhook'>webhook</option>
              </select>
            </div>
          </h3>
        </div>
      </div>
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          {['BankDataFetched', 'BankDataProcessed', 'AccountsDataPrepared', 'AccountsData', 'ConsentGiven', 'ProcessCancelled', 'ErrorOccurred'].map((keys, index) => (
            <ToggleField
              keys={keys}
              checked={clientDetails?.setting.webhook_events[keys]}
              toggleFunction={handleWebhookEvents}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
            />
          ))}
          <h3 className="headeing-switch-light">
            Enable Cerificate Sharing
            <div className="adjust-switcher">
              <div className="select-switch">
                <label className="switch-box">
                  <input type="checkbox" checked={clientDetails.setting.enable_cerificate_sharing} onChange={(e) => handleSetting(e, 'enable_cerificate_sharing')} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {
            clientDetails.setting.enable_cerificate_sharing && <div className='mb-3'><Select
              closeMenuOnSelect={false}
              onChange={multiSelectHandler}
              isMulti
              options={organizationList}
              styles={customStyles}
              defaultValue={sharedOrganizationSelect}
            /></div>
          }
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Download API/iframe setting
            </div>
            <div className="create-client-button">
              <button type='button' className='className="custom-button td-none"' onClick={(e) => exportToJson(e, orgIdShow)}>
                Export to JSON
              </button>
            </div>
          </h3>
        </div>
        <div className="details-left details-full-box">
          <ToggleField
            keys={'api_back_button'}
            checked={clientDetails?.setting.enable_back_button.api_back_button}
            toggleFunction={handleWebhookEvents}
            capitalizeFirstLetter={capitalizeFirstLetter}
            enable_back_button={'enable_back_button'}
          />
          {
            clientDetails?.setting.enable_back_button.api_back_button && <ToggleField
              keys={'api_cancel_button'}
              checked={clientDetails?.setting.enable_back_button.api_cancel_button}
              toggleFunction={handleWebhookEvents}
              capitalizeFirstLetter={capitalizeFirstLetter}
              enable_back_button={'enable_back_button'}
            />
          }
          <ToggleField
            keys={'iframe_back_button'}
            checked={clientDetails?.setting.enable_back_button.iframe_back_button}
            toggleFunction={handleWebhookEvents}
            capitalizeFirstLetter={capitalizeFirstLetter}
            enable_back_button={'enable_back_button'}

          />
          {
            clientDetails?.setting.enable_back_button.iframe_back_button && <ToggleField
              keys={'iframe_cancel_button'}
              checked={clientDetails?.setting.enable_back_button.iframe_cancel_button}
              toggleFunction={handleWebhookEvents}
              capitalizeFirstLetter={capitalizeFirstLetter}
              enable_back_button={'enable_back_button'}

            />
          }
          <ToggleField
            keys={'manual_back_button'}
            checked={clientDetails?.setting.enable_back_button.manual_back_button}
            toggleFunction={handleWebhookEvents}
            capitalizeFirstLetter={capitalizeFirstLetter}
            enable_back_button={'enable_back_button'}

          />
          {
            clientDetails?.setting.enable_back_button.manual_back_button && <ToggleField
              keys={'manual_cancel_button'}
              checked={clientDetails?.setting.enable_back_button.manual_cancel_button}
              toggleFunction={handleWebhookEvents}
              capitalizeFirstLetter={capitalizeFirstLetter}
              enable_back_button={'enable_back_button'}

            />
          }

          {["error_response", "use_old_http_status_on_v4", "download_report"].map((keys, index) => (
            <ToggleField
              keys={keys}
              checked={clientDetails?.setting[keys]}
              toggleFunction={handleSetting}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
            />
          ))}
          <h3 className="headeing-switch-light align-items-start detail-two-box">
            Automated Download Report
            <div className="add-download-report">
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            Certificate(pdf)
            <div className="adjust-switcher flex-column">
              <div className="select-switch mb-2 dReport-dd">
                <label className="switch-box">
                  <input type="checkbox" name={'certificate_pdf'} checked={isValueAvailable('pdf')} onChange={(e) => downloadReportHandler(e, 'certificate_pdf')} />
                  <span className="slider round"></span>
                </label>
              </div>
              {
                isValueAvailable('pdf') && <select className="form-control w-100 org-excludeDropdown" id="sel1" name='certificate_pdf' value={localeValue('pdf')} onChange={(e) => localeUpdateHandler(e, 'pdf')} disabled={!isValueAvailable('pdf')}>
                  <option value=''>Option</option>
                  <option value='sw'>swedish</option>
                  <option value='en'>english</option>
                </select>
              }
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            Transactions(xlsx)
            <div className="adjust-switcher flex-column">
              <div className="select-switch mb-2 dReport-dd">
                <label className="switch-box">
                  <input type="checkbox" name={'transactions_xlsx'} checked={isValueAvailable('xlsx')} onChange={(e) => downloadReportHandler(e, 'transactions_xlsx')} />
                  <span className="slider round"></span>
                </label>
              </div>
              {
                isValueAvailable('xlsx') && <select className="form-control w-100 org-excludeDropdown" id="sel1" name='transactions_xlsx' value={localeValue('xlsx')} onChange={(e) => localeUpdateHandler(e, 'xlsx')} disabled={!isValueAvailable('xlsx')}>
                  <option value=''>Option</option>
                  <option value='sw'>swedish</option>
                  <option value='en'>english</option>
                </select>
              }
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            Transactions(csv)
            <div className="adjust-switcher flex-column">
              <div className="select-switch mb-2 dReport-dd">
                <label className="switch-box">
                  <input type="checkbox" name={'transactions_csv'} checked={isValueAvailable('csv')} onChange={(e) => downloadReportHandler(e, 'transactions_csv')} />
                  <span className="slider round"></span>
                </label>
              </div>
              {
                isValueAvailable('csv') && <select className="form-control w-100 org-excludeDropdown" id="sel1" name='transactions_csv' value={localeValue('csv')} onChange={(e) => localeUpdateHandler(e, 'csv')} disabled={!isValueAvailable('csv')}>
                  <option value=''>Option</option>
                  <option value='sw'>swedish</option>
                  <option value='en'>english</option>
                </select>
              }
            </div>
          </h3>
        </div>
      </div>
      <div className="create-client-button">
        <button onClick={submitHandle}>Update</button>
      </div>
    </div>
  );
}

export default V4CustomizationTab;