import { useLocation } from "react-router-dom";
import backIcon from "../../../assets/images/icon_back.png";
import { useHistory } from "react-router-dom";
import TestEndpointInfoModal from "../modals/TestEndpointInfoModal";
import { pingEndpoint, getRequestsList, getOrganizationsEndpoints} from "../../../Redux/Actions/OrganizationsEndpoints";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import FlashMessage from "../../FlashMessage/FlashMessage";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import UpdateEndpointModal from "../modals/UpdateEndpointModal";
import { getOrganizationList } from "../../../Redux/Actions/OrganizationManagement";
import ReactSelect from "react-select";

const EndpointPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { endpoint } = location.state || {};
  const dispatch = useDispatch();
  const [testEndpointInfo, setTestEndpointInfo] = useState(null);
  const [flash, setFlash] = useState({ show: false, type: "", message: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [requestsList, setRequestsList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationNames, setOrganizationNames] = useState(location.state.organizationNames || []);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [updateEndpoint, setUpdateEndpoint] = useState(endpoint);
  const totalRequests = requestsList.length;
  const requestsLastMonth = requestsList.filter((request) => new Date(request.requested_at) >= new Date(new Date().setMonth(new Date().getMonth() - 1))).length;
  const requestsLast24Hours = requestsList.filter((request) => new Date(request.requested_at) >= new Date(new Date().setDate(new Date().getDate() - 1))).length;
  const errorRequests = requestsList.filter((request) => request.status_code !== 200).length;

  const fetchData = useCallback(async () => {
    dispatch(setLoaderSpinner(true));
  
    const fetchSingleEndpoint = async () => {
      try {
        const endpointsResponse = await dispatch(getOrganizationsEndpoints(endpoint.id));
        if (endpointsResponse.status) {
          const updatedEndpoint = endpointsResponse.data.data.analytics_endpoints.find(
            (ep) => ep.id === endpoint.id
          );
          if (updatedEndpoint) {
            setUpdateEndpoint(updatedEndpoint);
          }
        } else if (endpointsResponse.message === "Unauthorized") {
          history.push("/login");
        }
      } catch (error) {
        console.error("Error fetching endpoint:", error);
      }
    };
  
    const fetchEndpointsRequests = async () => {
      try {
        const requestResponse = await dispatch(getRequestsList(endpoint.id));
        if (requestResponse.status) {
          setRequestsList(requestResponse.data || []);
        } else {
          console.error("Error fetching requests list:", requestResponse.message);
        }
      } catch (error) {
        console.error("Error fetching requests list:", error);
      }
    };
  
    const fetchOrganizationList = async () => {
      let allOrganizations = [];
      let page = 1;
      let totalPages = 1;
      try {
        do {
          const response = await dispatch(getOrganizationList(`?page=${page}`));
          if (response.status) {
            allOrganizations = allOrganizations.concat(response.data.organizations || []);
            totalPages = response.data.total_pages;
            page += 1;
          } else if (response.message === "Unauthorized") {
            history.push("/login");
            break;
          }
        } while (page <= totalPages);
        setOrganizationsList(allOrganizations);
        const updatedOrganizationNames = allOrganizations
          .filter((org) => org.analytics_endpoint_id === endpoint.id)
          .map((org) => org.name);
        setOrganizationNames(updatedOrganizationNames);
      } catch (error) {
        console.error("Failed to fetch all organizations", error);
      }
    };
    await Promise.all([fetchSingleEndpoint(), fetchEndpointsRequests(), fetchOrganizationList()]);
  
    dispatch(setLoaderSpinner(false));
  }, [dispatch, endpoint.id, history]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTestEndpoint = (endpoint) => {
    if (!selectedOrgId) {
      setFlash({
        show: true,
        type: "error",
        message: "Please select an organization before testing the endpoint.",
      });
      return;
    }
    dispatch(setLoaderSpinner(true));
    dispatch(pingEndpoint({ analytics_endpoint: endpoint }, selectedOrgId))
      .then((res) => {
        dispatch(setLoaderSpinner(false));
        if (res.status) {
          setTestEndpointInfo(res.data);
          setIsModalOpen(true);
          setFlash({
            show: true,
            type: "success",
            message: "Ping was successful!",
          });
          setSelectedOrgId(null);
          setSelectedOrg(null);
        } else {
          setFlash({
            show: true,
            type: "error",
            message: "Ping failed. Please try again.",
          });
        }
      })
      .catch((err) => {
        console.error("Failed to ping endpoint:", err);
        setFlash({
          show: true,
          type: "error",
          message: "An error occurred while pinging the endpoint.",
        });
        dispatch(setLoaderSpinner(false));
      });
  };

  useEffect(() => {
    if (flash.show) {
      const timeoutId = setTimeout(() => {
        setFlash({ ...flash, show: false });
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [flash]);

  const handleOpenUpdateModalClick = () => {
    setIsUpdateModalOpen(true);
  };

  const handleOrgChange = (selectedOption) => {
    setSelectedOrgId(selectedOption ? selectedOption.value : null);
    setSelectedOrg(selectedOption);
  };
  
  return (
    <div className="broker-flow-container">
      <div className="endpoint-flow-ld-container">
        <span
          onClick={() => history.push("/org_endpoints")}
          className="esu-icon-wrapper"
        >
          <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
        </span>
        <h3 className="ld-header-broker-flow">Organizations - Endpoints</h3>
      </div>
      <div className="backgroundJobs-table-top bg-white">
        {flash.show && (
          <FlashMessage
            iconImage={flash.type === "success" ? "tick" : "alert"}
            firstMsg={flash.type === "success" ? "Success" : "Error"}
            secondMsg={flash.message}
            closeFlash={() => setFlash({ ...flash, show: false })}
          />
        )}
        <div className="endpoint-details-header-line">
          <h3 className="endpoint-header">Endpoint: {endpoint.name}</h3>
          <button
            className="endpoint-create-button"
            onClick={handleOpenUpdateModalClick}
          >
            Edit Endpoint
          </button>
        </div>
        <div className="endpoint-details-test-button">
        <ReactSelect
          value={selectedOrg}
          onChange={handleOrgChange}
          options={organizationsList
            .filter(
              (organization) =>
                organization.analytics_endpoint_id === endpoint.id
            )
            .map((organization) => ({
              value: organization.id,
              label: `${organization.name}: ${organization.id}`,
            }))}
          placeholder="Select organization"
          isClearable={true}
          noOptionsMessage={() => "No organizations found"}
          className="email-sms-select-org-dropdown"
        />
        <button
          className="endpoint-button"
          onClick={() => handleTestEndpoint(endpoint)}
        >
          Test
        </button>
        </div>
        <div className="endpoint-statistics-section">
          <h4 className="endpoint-statistics-header">Statistics</h4>
          <div className="endpoint-statistics">
            <p>
              <span className="endpoint-statistics-value">Total Requests:</span>{" "}
              {totalRequests}
            </p>
            <p>
              <span className="endpoint-statistics-value">
                Requests Last Month:
              </span>{" "}
              {requestsLastMonth}
            </p>
            <p>
              <span className="endpoint-statistics-value">
                Requests Last 24 Hours:
              </span>{" "}
              {requestsLast24Hours}
            </p>
            <p>
              <span className="endpoint-statistics-value">Error Requests:</span>{" "}
              {errorRequests}
            </p>
          </div>
        </div>
        <div className="backgroundJobs-thead-scroll-y">
          {requestsList.length === 0 ? (
            <div className="endpoint-no-message">
              No requests available for the selected endpoint
            </div>
          ) : (
            <table className="backgroundJobs-table" width="100%">
              <thead className="endpoint-details-thead">
                <tr>
                  <th className="backgroundJobs-head">ID</th>
                  <th className="backgroundJobs-head">Date</th>
                  <th className="backgroundJobs-head">Execution Time</th>
                  <th className="backgroundJobs-head">Status</th>
                  <th className="backgroundJobs-head">Valid</th>
                  <th className="backgroundJobs-head">Errors</th>
                </tr>
              </thead>
              <tbody className="endpoint-tbody">
                {requestsList.map((request) => {
                  const executionTimeToSeconds = request.execution_time / 1000;
                    return (
                    <React.Fragment key={request.id}>
                      <tr className="user-box">
                      <td>{request.id}</td>
                      <td>{new Date(request.requested_at).toLocaleString()}</td>
                      <td>{executionTimeToSeconds}s</td>
                      <td className={request.status_code === 200 ? "success-result" : "failed-result"}>
                        {request.status_code}
                      </td>
                      <td className={request.is_valid ? "success-result" : "failed-result"}>
                        {request.is_valid ? "Yes" : "No"}
                      </td>
                      <td className="failed-result">
                        {request.errors || ""}
                      </td>
                      </tr>
                    </React.Fragment>
                    );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isModalOpen && testEndpointInfo && (
        <TestEndpointInfoModal
          handleCloseModal={() => {
            setIsModalOpen(false)
            fetchData();
          }}
          testEndpointInfo={testEndpointInfo}
        />
      )}
      {isUpdateModalOpen && (
        <UpdateEndpointModal
          organizationsList={organizationsList}
          endpoint={updateEndpoint}
          handleCloseModal={() => {
            setIsUpdateModalOpen(false);
            fetchData();
          }}
          organizationNames={organizationNames}
          setFlash={setFlash}
        />
      )}
    </div>
  );
};

export default EndpointPage;